<template>
  <div>
    <form-wizard
      color="#1A83B9"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Envoyer"
      back-button-text="Précédent"
      next-button-text="Suivant"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- personal details tab -->
      <tab-content
        title="Informations du patient"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              offset="2"
              cols="8"
              class="mb-2"
            >
              <!--              <b-input-group class="input-group-merge">-->
              <!--                <b-input-group-prepend is-text>-->
              <!--                  <feather-icon icon="SearchIcon"/>-->
              <!--                </b-input-group-prepend>-->
              <!--                <b-form-input-->
              <!--                  v-model="searchPatient"-->
              <!--                  placeholder="Recherche multicritères"-->
              <!--                />-->
              <!--              </b-input-group>-->
              <!--              <ul>-->
              <!--                <li-->
              <!--                  v-for="p in filteredPatients"-->
              <!--                  :key="p.id"-->
              <!--                >{{ p.prenom + ' ' + p.nom }}-->
              <!--                </li>-->
              <!--              </ul>-->

              <vue-autosuggest
                v-model="selected"
                :suggestions="filteredOptions"
                :limit="10"
                :input-props="{id:'autosuggest__input',class:'form-control', placeholder:'Recherche multicritères'}"
                :get-suggestion-value="getSuggestionValue"
                @input="onInputChange"
              >
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.prenom }}</span>
                </template>
              </vue-autosuggest>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Identifiant"
                label-for="identifiant"
              >
                <validation-provider
                  #default="{ errors }"
                  name="identifiant"
                >
                  <b-form-input
                    id="Identifiant"
                    v-model="patient.id"
                    placeholder="identifiant du patient"
                    :state="errors.length ? false:null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Prénom"
                label-for="prenom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prenom"
                  rules="required"
                >
                  <b-form-input
                    id="prenom"
                    v-model="patient.prenom"
                    :state="errors.length? false:null"
                    placeholder="Entrez le prénom du patient"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Nom"
                label-for="nom"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nom"
                  rules="required"
                >
                  <b-form-input
                    id="nom"
                    v-model="patient.nom"
                    placeholder="Entrez le nom du patient"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="residence"
                rules="required"
              >
                <b-form-group
                  label="Residence"
                  label-for="residence"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="country"
                    v-model="patient.residence"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsResidence"
                    label="text"
                    placeholder="Sélectionnez une residence"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="sexe"
                rules="required"
              >
                <b-form-group
                  label="Sexe"
                  label-for="sexe"
                  :state="errors.length ? false:null"
                >
                  <v-select
                    id="country"
                    v-model="patient.sexe"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsSexe"
                    :selectable="option => !option.value.includes('select_value')"
                    label="text"
                    placeholder="Sélectionnez le sexe du patient"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Date de naissance"
                label-for="naissance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="naissance"
                  rules="required"
                >
                  <b-form-input
                    id="adresse"
                    v-model="patient.date_naissance"
                    type="date"
                    :state="errors.length ? false:null"
                    placeholder="Entrez l'adresse du patient"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Téléphone"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="patient.phone"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Entrez le numéro du patient"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Adresse"
                label-for="adresse"
              >
                <validation-provider
                  #default="{ errors }"
                  name="adresse"
                >
                  <b-form-input
                    id="adresse"
                    v-model="patient.address"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Entrez l'adresse du patient"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Les consultations du patient"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col md="3">
              <validation-provider
                #default="{ errors }"
                name="prise_en_charge"
              >
                <b-form-group
                  label="Prise en charge"
                  label-for="prise_en_charge"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="country"
                    v-model="patient.prise_en_charge"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsPC"
                    label="text"
                    placeholder="Aucune prise en charge"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="patient.prise_en_charge">
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="num_assurance"
                rules="required"
              >
                <b-form-group
                  label="Numéro Assurance"
                  label-for="num_assurance"
                  :state="errors.length > 0 ? false : null"
                >
                  <validation-provider
                    name="num_assurance"
                  >
                    <b-form-input
                      id="num_assurance"
                      v-model="patient.assurance.number"
                      :state="errors.length ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="ogd"
                rules="required"
              >
                <b-form-group
                  label="OGD"
                  label-for="ogd"
                  :state="errors.length ? false : null"
                >
                  <v-select
                    id="ogd"
                    v-model="patient.assurance.ogd"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsOGD"
                    :selectable="option => !option.value.includes('select_value')"
                    label="text"
                    placeholder=""
                  />
                  <b-form-invalid-feedback :state="errors.length ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="beneficiary"
                rules="required"
              >
                <b-form-group
                  label="Bénéficiaire"
                  label-for="beneficiary"
                  :state="errors.length ? false:null"
                >
                  <v-select
                    id="beneficiary"
                    v-model="patient.assurance.beneficiary"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsBeneficiary"
                    :selectable="option => !option.value.includes('select_value')"
                    label="text"
                    placeholder=""
                  />
                  <b-form-invalid-feedback :state="errors.length ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="other"
              >
                <b-form-group
                  label="Autre info"
                  label-for="other"
                  :state="errors.length ? false:null"
                >
                  <validation-provider
                    name="other"
                  >
                    <b-form-input
                      id="other"
                      v-model="patient.assurance.other_info"
                      :state="errors.length ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="2">
              <validation-provider
                #default="{ errors }"
                name="rate"
                rules="required"
              >
                <b-form-group
                  label="Taux"
                  label-for="rate"
                  :state="errors.length ? false:null"
                >
                  <validation-provider
                    name="rate"
                  >
                    <b-form-input
                      id="rate"
                      v-model="patient.assurance.rate"
                      :state="errors.length ? false:null"
                      disabled="disabled"
                      value="80"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Consultation"
                label-for="consultation"
              >
                <validation-provider
                  #default="{ errors }"
                  name="consultation"
                >
                  <v-select
                    id="consultation"
                    v-model="consultation.prestation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="consultations"
                    label="text"
                    placeholder="Sélectionnez une prestation"
                  />
                  <!--                  <b-form-input-->
                  <!--                    v-model="consultation.name"-->
                  <!--                    list="consultation"-->
                  <!--                    :state="errors.length > 0 ? false:null"-->
                  <!--                    placeholder="Sélectionnez une consultation"-->
                  <!--                  />-->
                  <!--                  <datalist-->
                  <!--                    id="consultation"-->
                  <!--                    ref="consultation"-->
                  <!--                  >-->
                  <!--                    <option-->
                  <!--                      v-for="c in consultations"-->
                  <!--                      :key="c.id"-->
                  <!--                    >-->
                  <!--                      {{ c.libelle }}-->
                  <!--                    </option>-->
                  <!--                  </datalist>-->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Médecins"
                label-for="medecin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="medecin"
                >
                  <v-select
                    id="medecin"
                    v-model="consultation.medecin"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="medecins"
                    label="text"
                    placeholder="Aucune Médecin"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Quantité"
                label-for="quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="quantity"
                  rules="required"
                >
                  <b-form-input
                    id="quantity"
                    v-model="consultation.quantity"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Prix"
                label-for="prix"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prix"
                >
                  <b-form-input
                    id="prix"
                    v-model="consultation.montant"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="consultation.montant"
              md="2"
            >
              <b-badge
                variant="primary"
                class="badge-label mt-2"
              >
                Total : {{ consultation.montant * consultation.quantity }} F CFA
              </b-badge>
            </b-col>
            <b-col md="1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon mt-2"
                @click="addConsultationItem(consultation)"
              >

                <feather-icon
                  icon="PlusCircleIcon"
                  size="18"
                />
              </b-button>
            </b-col>
          </b-row>
          <br>
          <br>
          <b-row>
            <b-col md="12">
              <b-table
                responsive
                :items="consultations_items"
                :fields="fields"
                class="mb-0"
              >
                <template v-slot:cell(prestation)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.prestation.libelle }}</strong>
                  </span>
                </template>
                <template v-slot:cell(medecin)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.medecin.text }}</strong>
                  </span>
                </template>
                <template v-slot:cell(prix)="data">
                  <span class="text-nowrap">
                    <strong>{{ data.item.montant }}</strong>
                  </span>
                </template>

                <!-- Optional default data cell scoped slot -->
                <template v-slot:cell(montant)="data">
                  {{ data.item.montant * data.item.quantity }}
                </template>
                <template v-slot:cell(actions)="data">
                  <b-button
                    size="sm"
                    variant="danger"
                    @click="deleteItem(data.item)"
                  >
                    Supprimer
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col
              offset="8"
              cols="4"
            >
              <b-badge
                variant="primary"
                class="badge-label"
              >
                Total : {{ getMontantTotal() }}F CFA
              </b-badge>
              <br>
              <b-badge
                v-if="patient.assurance.rate"
                variant="primary"
                class="badge-label"
              >
                Prise en charge :
                {{ getPriseEnCharge() }} F CFA
              </b-badge>
              <br>
              <b-badge
                v-if="patient.assurance.rate"
                variant="success"
                class="badge-label"
              >
                Reste à payer : {{ getResteAPayer() }} F CFA
              </b-badge>
              <br>
              <b-badge
                variant="primary"
                class="badge-label"
              >
                Total à percevoir : {{ getTotalAPercevoir() }} F CFA
              </b-badge>
              <br>
              <b-badge
                variant="primary"
                class="badge-label"
              >
                Montant reçu : <input
                v-model="prestation_montants.montant_recu"
                type="number"
                style="width: 140px;height: 30px;border-radius: 5px;"
              >
              </b-badge>
              <br>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>

  </div>
</template>>

<script>
import {VueAutosuggest} from 'vue-autosuggest'
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {FormWizard, TabContent} from 'vue-form-wizard'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {required, email} from '@validations'
import showToast, {restObject} from '@/helpers/methodes'
import getOGD, {
  getTypeAssurance, getActes, getMedecins, areMethodsValid, postCaisseData, getResidences, getPatients,
} from '@/api-services/caisse'
import {forEach} from 'core-js/stable/dom-collections'
import {codeIcon} from './code'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BTable,
    BBadge,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      consultation: {
        id: null,
        prestation: [],
        medecin: null,
        quantity: 1,
        montant: 0,
      },
      fields: [
        'id',
        'prestation',
        'medecin',
        'quantity',
        {
          key: 'prix',
          label: 'Prix',
        },
        {
          key: 'montant',
          label: 'Montant',
        },
        {
          key: 'actions',
          label: 'Actions',
        },
      ],
      consultations_items: [],
      test: '',
      consultations: [],
      prestation_montants: {
        montant_recu: 0,
      },
      // prixConsultations: []
      medecins: [],
      patient: {
        id: '',
        identifiant: '',
        prenom: '',
        nom: '',
        residence: null,
        sexe: null,
        phone: '',
        address: '',
        prise_en_charge: '',
        date_naissance: '',
        assurance: {
          id: null,
          number: null,
          ogd: null,
          beneficiary: null,
          other_info: null,
          rate: 80,
        },
      },
      selectedContry: '',
      selectedLanguage: '',
      required,
      email,
      codeIcon,
      countryName: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'Russia',
          text: 'Russia',
        },
        {
          value: 'Canada',
          text: 'Canada',
        },
        {
          value: 'China',
          text: 'China',
        },
        {
          value: 'United States',
          text: 'United States',
        },
        {
          value: 'Brazil',
          text: 'Brazil',
        },
        {
          value: 'Australia',
          text: 'Australia',
        },
        {
          value: 'India',
          text: 'India',
        },
      ],
      languageName: [
        {
          value: 'nothing_selected',
          text: 'Nothing Selected',
        },
        {
          value: 'English',
          text: 'English',
        },
        {
          value: 'Chinese',
          text: 'Mandarin Chinese',
        },
        {
          value: 'Hindi',
          text: 'Hindi',
        },
        {
          value: 'Spanish',
          text: 'Spanish',
        },
        {
          value: 'Arabic',
          text: 'Arabic',
        },
        {
          value: 'Malay',
          text: 'Malay',
        },
        {
          value: 'Russian',
          text: 'Russian',
        },
      ],
      selectedPC: null,
      optionsSexe: [
        {
          value: 'Masculin',
          text: 'Masculin',
        },
        {
          value: 'Feminin',
          text: 'Feminin',
        },
      ],
      optionsOGD: [
        {
          value: 'select_value',
          text: 'Sélectionnez un ogd',
        },
        {
          value: 'inps',
          text: 'INPS',
        },
        {
          value: 'cmss',
          text: 'CMSS',
        },
      ],
      optionsBeneficiary: [
        {
          value: 'select_value',
          text: 'Sélectionnez un bénéficiaire',
        },
        {
          value: 'titulaire',
          text: 'Titulaire',
        },
        {
          value: 'enfant',
          text: 'Enfant',
        },
        {
          value: 'parent',
          text: 'Parent',
        },
      ],
      optionsPC: [],
      optionsResidence: [],
      userId: '',
      occupationID: '',
      filteredPatients: [],
      patients: [],
      searchPatient: '',

      datasuggest: [],
      filteredOptions: [],
      limit: 10,
      selected: null,
    }
  },
  computed: {
    validation() {
      return this.userId.length > 4 && this.userId.length < 13
    },
    validationOccupation() {
      return this.occupationID.length > 1
    },
  },

  watch: {
    'consultation.prestation': {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        this.consultation.montant = val.prix_acte
      },
    },
    'patient.prise_en_charge': {
      deep: true,
      immediate: true,
      handler(val, oldVal) {
        const filteredPriseEnCharge = Object.entries(this.optionsPC)
          .filter(([id, priseEnCharge]) => priseEnCharge.libelle === val.libelle)
          .reduce((obj, [id, priseEnCharge]) => {
            // eslint-disable-next-line no-param-reassign
            obj[id] = priseEnCharge
            return priseEnCharge
          }, {})
        this.patient.assurance.rate = filteredPriseEnCharge.taux
      },
    },
    searchPatient(val) {
      this.filteredPatients = this.patients.filter(patient => patient.prenom.includes(val))
    },
  },

  created() {
    this.$http.get('/autosuggest/data')
      .then(res => {
        this.datasuggest = res
      })
    getOGD(this)
      .then(res => {
        this.optionsOGD = res.data
      })
    getTypeAssurance(this)
      .then(res => {
        this.optionsPC = res.data
      })
    getResidences(this)
      .then(res => {
        this.optionsResidence = res.data
      })
    getActes(this)
      .then(res => {
        this.consultations = res.data
      })
    getMedecins(this)
      .then(res => {
        this.medecins = res.data
      })
    getPatients(this)
      .then(res => {
        this.patients = res.data
        console.log(this.patients)
      })
  },
  updated() {
  },
  methods: {
    getSuggestionValue(suggestion) {
      this.patient.id = suggestion.item.id
      this.patient.prenom = suggestion.item.prenom
      this.patient.nom = suggestion.item.nom
      this.patient.address = suggestion.item.adresse
      this.patient.phone = suggestion.item.telephone
      this.patient.date_naissance = suggestion.item.date_naissance
      if (suggestion.genre === 'Masculin') {
        this.patient.sexe = {
          value: 'Masculin',
          text: 'Masculin',
        }
      } else {
        this.patient.sexe = {
          value: 'Feminin',
          text: 'Feminin',
        }
      }
      this.optionsResidence.forEach(residence => {
        if (residence.id === suggestion.item.residence) {
          this.patient.residence = residence
        }
      })

      return suggestion.item.prenom
    },
    getPriseEnCharge() {
      // eslint-disable-next-line no-mixed-operators
      return this.patient.assurance.rate / 100 * this.getMontantTotal()
    },
    getMontantTotal() {
      return (this.consultations_items.reduce((acc, item) => acc + (item.montant * item.quantity), 0))
    },
    getResteAPayer() {
      return this.getMontantTotal() - this.getPriseEnCharge()
    },
    getTotalAPercevoir() {
      if (this.patient.assurance.rate) {
        return this.getResteAPayer()
      }
      return this.getMontantTotal()
    },
    reset() {
    },
    formSubmitted() {
      if (!areMethodsValid(this.patient) && this.consultations.length) {
        this.prestation_montants.montant_total = this.getMontantTotal()
        this.prestation_montants.prise_en_charge = this.getPriseEnCharge()
        this.prestation_montants.reste_a_payer = this.getResteAPayer()
        this.prestation_montants.total_a_percevoir = this.getTotalAPercevoir()
        postCaisseData(this, {
          patient: this.patient,
          prestationMontant: this.prestation_montants,
          prestationDetails: this.consultations_items,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Enregistré',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name: 'caisse-tickets-carte'})
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ticket Enregistré',
                icon: 'CancelIcon',
                variant: 'danger',
              },
            })
            console.log('Error', error)
          })
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
    addConsultationItem(item) {
      const existingItem = this.consultations_items.find(i => i.prestation.id === item.prestation.id)
      if (!existingItem) {
        this.consultation.id = 1
        if (!areMethodsValid(this.consultation)) {
          showToast(this, 'danger', 'Erreur', 'vérifier la ligne')
          return
        }
        if (!(this.checkConsultationItem() > 0)) {
          const consultationCopy = {
            id: this.consultation.id,
            prestation: this.consultation.prestation,
            acte_id: this.consultation.id,
            medecin: this.consultation.medecin,
            quantity: this.consultation.quantity,
            montant: this.consultation.montant,
          }
          this.consultations_items.push(consultationCopy)
          showToast(this, 'info', 'Ajout', `${this.consultation.prestation.libelle} ajoutée`)
          restObject(this.consultation, {
            quantity: 1,
            montant: 0,
            prestation: [],
          })
        } else {
          showToast(this, 'danger', 'Ajout', `${this.consultation.prestation.libelle} non ajoutée`)
        }
      } else {
        showToast(this, 'danger', 'Ajout', `${this.consultation.prestation.libelle} existe deja `)
      }
    },
    checkConsultationItem() {
      let occurences = 0
      this.consultation.id = this.consultations_items.length + 1
      for (let i = 0; i < this.consultations_items.length; i++) {
        if (this.consultations_items[i].name === this.consultation.name) {
          occurences++
        }
      }
      //  occurences = this.consultations_items.find(consultation => consultation.name > this.consultation.name)
      // console.log(occurences, this.consultations_items, this.consultation.name)
      return occurences
    },
    deleteItem(item) {
      const index = this.consultations_items.indexOf(item)
      this.consultations_items.splice(index, 1)
    },
    // total
    // prise_en_charge
    // reste_a_payer
    // total_a_recevoir
    // montant_recu
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredPatientPrenom = this.patients.filter(item => item.prenom.toLowerCase()
        .indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const filteredPatientNom = this.patients.filter(item => item.nom.toLowerCase()
        .indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit)

      const filteredData = filteredPatientPrenom.concat(filteredPatientNom)
      this.filteredOptions = [{
        data: filteredData,
      }]
      console.log(this.patient)
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
